.App {
  font-family: sans-serif;
  text-align: left;
}

body,
html {
  margin: 0;
  padding: 0;
}

:root {
  --primary: #006aff;
  --secondary: #1e293b;
  --accent: #3182ce;
  --text: #2a2a33;
  --text-light: #697280;
  --background: #f9f9fb;
  --border: #e6e6e8;
  --warning: #ff5a5f;
  --success: #00a699;
}

.report-content figure.photo-placeholder img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  border-radius: 0.75rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.report-content figure.photo-placeholder figcaption {
  text-align: center;
  margin-top: 0.5rem;
  font-style: italic;
}

.report-content figure.photo-placeholder {
  text-align: center;
  margin: 1.5rem 0;
}

.report-content h2.start-margin {
  margin: 0.5rem 0;
}

.report-content header .last-updated {
  padding-bottom: 0rem;
  margin-bottom: 0rem;
}

.report-content header .container {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.report-content {
  font-family: system-ui, -apple-system, sans-serif;
  line-height: 1.6;
  color: var(--text);
}

.report-content .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.report-content header {
  background: white;
  padding: 0rem 0;
}

.report-content h1,
.report-content h2,
.report-content h3,
.report-content h4 {
  font-weight: 600;
  line-height: 1.3;
}

.report-content h1 {
  color: var(--accent);
  font-size: 2.5rem;
  margin: 0 0 1rem;
}

.report-content h2 {
  color: var(--secondary);
  border-bottom: 2px solid var(--border);
  padding-bottom: 0.5rem;
  margin: 2.5rem 0 1.5rem;
}

.report-content a {
  color: var(--primary);
  text-decoration: none;
  transition: all 0.2s ease;
}

.report-content a:hover {
  text-decoration: underline;
  opacity: 0.8;
}

.report-content .stat-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  margin: 1.5rem 0;
}

.report-content .stat-card {
  background: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--border);
}

.report-content .stat-card h4 {
  margin-top: 0;
}

.report-content .rating-badge {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  background-color: var(--primary);
  color: white;
  font-weight: 600;
  font-size: 0.875rem;
  margin: 0.25rem 0;
}

.report-content .historic-note {
  background-color: #ebf8ff;
  border-left: 5px solid var(--primary);
  padding: 1.5rem;
  margin: 1.5rem 0;
  border-radius: 0.75rem;
}

.report-content .warning-box {
  background-color: #fff4f0;
  border-left: 5px solid var(--warning);
  padding: 1.5rem;
  margin: 1.5rem 0;
  border-radius: 0.75rem;
}

.report-content .school-card {
  background: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--border);
  margin-bottom: 1rem;
}

.report-content .school-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .report-content .container {
    padding: 1rem;
  }

  .report-content h1 {
    font-size: 2rem;
  }

  .report-content .stat-grid {
    grid-template-columns: 1fr;
  }
}
